import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
export default () => (
  <Layout>
    <SEO title="Privacy" />
    <div className="privacy-policy privacy-wrapper">
      <strong>Referral Program</strong>
      <p>
        Our goal is to streamline and optimize e-commerce operations. With the
        Upright Labs Referral Program, you&#39;ll earn awards each time you
        refer a company to Upright Labs that becomes a client of Upright Labs.
        Note that referrals cannot be added after a conversation with a
        prospective client have already started.{" "}
      </p>
      <strong>Upright Lister</strong>
      <p>
        If you successfully refer a &quot;Client&quot; to Upright Lister, your
        company receives 0.20% of that company&#39;s e-commerce revenue managed
        through Upright Lister, deduced from your your company&#39;s monthly
        bill for the first three months they are a client of Upright Lister. If
        your referral fee is more than your monthly invoice, the difference is
        rolled over to your next month as a credit on your invoice.{" "}
      </p>
      <p>
        A &quot;Client&quot; is defined as the company you introduce Upright
        Labs to, becomes a paying customer of Upright Labs. A &quot;successful
        referral&quot; is when you introduce a client to Upright Labs, and they
        become a paying customer within 120 days of that introduction.
      </p>
      <strong>Upright Services</strong>
      <p>
        If you successfully refer a &quot;Client&quot; to Upright Services, you
        are eligibility to receive $2,500 worth of free service engagements for
        your company. A &quot;Client&quot; is defined as the company you
        introduce Upright Labs to that becomes a paying customer of Upright Labs
        services.
      </p>
      <strong>Limits</strong>
      <p>
        All introductions must be made with sales@uprightlabs.com cc&#39;d on an
        email thread with a personal introduction to the Upright Labs team. The
        email must provide context on who are you introducing us to and why they
        would benefit from the Upright Labs offerings.{" "}
      </p>
      <p>
        If multiple introductions are made by different people to the same
        company, then the first person who provided the introduction will be
        provided the rewards as specified under the referral program. If
        multiple introductions are made to the same company, but an introduction
        does not result in a close, the credit will be given to the introduction
        that ultimately results in a client closing. Upright Labs will only
        provide one referral benefit for each company that becomes a client of
        Upright Labs.{" "}
      </p>
      <p>
        If a customer refers a client to both Upright Lister and Upright
        services, said customer is eligible for both benefits of Upright Lister
        and services.{" "}
      </p>
      <p>
        Referral eligibility only valid for 120 days from the date of
        introduction. If you refer a potential client to Upright Labs, you will
        only be provided the offerings as stated in the referral program if they
        sign on as a client within 120 days of the initial introduction.{" "}
      </p>
      <p>
        Referral awards will be disbursed after new client has paid their
        initial invoice to Upright Labs.{" "}
      </p>
      <strong>Eligibility</strong>
      <p>
        Must be at least 18 years old to be eligible for benefits and are
        non-transferable or redeemable for cash. The customer is responsible for
        all taxes and local requirements and fees. Program and awards are
        conditional on and subject to local laws and regulations. Must be a
        current client of Upright Labs to qualify for the referral program.
      </p>
      <p>
        The customer may not be an employee, legal representative or any
        Affiliate of Upright Labs. Nothing in the Referral Program shall be
        deemed to create any kind of relationship between the referrer and
        Upright Labs or any of Upright Labs’s Affiliates. The referrer has no
        authority to represent or bind Upright Labs. Terms and conditions are
        subject to change.
      </p>
      <strong>Good Faith</strong>
      <p>
        We introduce programs such as these in good faith and expect the same
        good faith in return. Please note that we may withhold awards where we
        believe customers are acting in bad faith or otherwise acting contrary
        to the intent of this program. To be clear, commercializing,
        advertising, publishing, mass distributing, selling or paying for use of
        the referral program is not appropriate, and we will not honor such
        introductions. We cannot cover every nefarious scenario, nor will we
        attempt to, but we do promise to be fair and reasonable.
      </p>
      <p>Not OK:</p>
      <ul>
        <li>Advertising</li>
        <li>Spam</li>
        <li>Anything misleading or inappropriate</li>
      </ul>
      <p>OK:</p>
      <ul>
        <li>YouTube channels</li>
        <li>Social media sharing</li>
        <li>Posting in online web forums</li>
      </ul>
    </div>
  </Layout>
)
